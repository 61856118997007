import React from "react";
import { Route } from "react-router-dom";
import Layout from "../components/shared/layouts/Layout";

const PublicRoute = props => {
    const { component: Component, ...rest } = props

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    )
}

export default PublicRoute