import React from "react";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { MaterialThemeProvider } from "./styles/MaterialThemeProvider";

const App = () => {
    return (
        <BrowserRouter>
            <MaterialThemeProvider>
                <Router />
            </MaterialThemeProvider>
        </BrowserRouter>
    );
};

export default App;
